import { homeImages } from "../../constants/images";
import { variables } from "../../constants/variables";
import Button from "../Button/Button";
import CommonPoster from "../CommonPoster/CommonPoster";
import "./HomePoster.css";
import { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

const styles = {
  topLeftContainer: {
    backgroundColor: variables.shades.light,
    padding: "40px 0px",
    minHeight: "450px",
  },
  topRightContainer: {
    padding: "0px",
  },
  title: {
    paddingHorizontal: "10px",
    color: variables.colors.text.primary,
    fontWeight: "bolder",
  },
  topParagraph: {
    color: variables.colors.text.secondary,
    padding: "5px 0px",
  },
};

function HomePoster() {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <div
      className="row"
      style={{ margin: "0px", padding: "0px" }}
      data-aos="fade"
      data-aos-delay="200"
    >
      <div
        className="col-lg-6 d-flex justify-content-center"
        style={styles.topLeftContainer}
      >
        <div className="col-10 col-lg-7 px-3 d-flex flex-column justify-content-around">
          <div style={styles.title}>
            <h1 className="h1g">Why people choose us</h1>
          </div>
          <div className="row" style={{ margin: "0px", padding: "0px" }}>
            <div className="col-1 dot" style={{ padding: "0px" }}>
              •
            </div>
            <div className="col-11" style={styles.topParagraph}>
              Fully licensed, bonded and insured with industry leading
              manufacturer warranties.
            </div>
            <div className="col-1 dot" style={{ padding: "0px" }}>
              •
            </div>
            <div className="col-11" style={styles.topParagraph}>
              The best customer service in the business, no joke! Check it out
              by yourself right now.
            </div>
            <div className="col-1 dot" style={{ padding: "0px" }}>
              •
            </div>
            <div className="col-11" style={styles.topParagraph}>
              The highest quality products and certified roofing technicians on
              every project.
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-lg-6 d-flex justify-content-center"
        style={styles.topRightContainer}
      >
        <img src={homeImages.home4} width="100%" height="100%" />
      </div>
      <CommonPoster></CommonPoster>
    </div>
  );
}

export default HomePoster;
