import { Cards, Hero, HomePoster } from "../../components";
import Clients from "../../components/Clients/Clients";
import Footer from "../../components/Footer/Footer";
import { homeImages } from "../../constants/images";
import { CardsList } from "../../containers";
import "./Home.css";
import { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

const styles = {
  homeContainer: {
    // width: '95%',
    margin: "auto",
  },
};

const data = [
  {
    img: homeImages.home1,
    title: "Residential",
    paragraph:
      "Roofologist101 dedicated to serving your needs in a timely manner, emphasising on quality and service excellence.",
  },
  {
    img: homeImages.home2,
    title: "Commercial",
    paragraph:
      "A building's roof is its first barrier against adverse weather. Keeping your roof in excellent shape protecxts your building from water intrusion.",
  },
  {
    img: homeImages.home3,
    title: "Repairs",
    paragraph:
      "With every residential and commercial roofing job, we focus on the details to ensure that your roofing problems are solved immediately.",
  },
];

function Home() {
  useEffect(() => {
    AOS.init({});

    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="" style={styles.homeContainer}>
      <Hero></Hero>
      <div className="headin" data-aos="fade" data-aos-delay="200">
        <h1 className="h1g">
          Trust The Experts For
          <br />
          All Your Roofing Needs
        </h1>
        <div className="marking"></div>
      </div>
      <CardsList data={data}></CardsList>
      <br />
      <HomePoster></HomePoster>
      <Clients></Clients>
      <Footer></Footer>
    </div>
  );
}

export default Home;
