export const variables = {
  colors: {
    primary: "#2e3192",
    secondary: "",
    text: {
      primary: "#2e3192",
      secondary: "#606061",
      tertiary: "#ffd127",
      white: "#ffffff",
      light: "#d7d7d7",
    },
    border: "#ffd127",
  },
  shades: {
    white: "#ffffff",
    light: "#f2f2f2",
    dark: "#2f2f2f",
    black: "#0000000",
  },
  images: {
    car: "https://imageio.forbes.com/specials-images/imageserve/5d35eacaf1176b0008974b54/0x0.jpg?format=jpg&crop=4560,2565,x790,y784,safe&width=1200",
  },
  font: {
    helvetica: "'Helvetica Neue', sans-serif",
  },
};
