import { variables } from "../../constants/variables";
import Button from "../Button/Button";
import "./Cards.css";
import { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import { Link } from "react-router-dom";

const styles = {
  cardContainer: {
    //     backgroundColor: variables.shades.light,
    //     minHeight: "515px",
    padding: "0px 0px 20px 0px",
  },
  cardTitle: {
    minHeight: "40px",
    color: variables.colors.text.primary,
    textAlign: "center",
    fontWeight: "bold",
  },
  cardParagraph: { textAlign: "center" },
  cardButtonContainer: {
    textAlign: "center",
  },
};

function Cards({ img, title, paragraph }) {
  useEffect(() => {
    AOS.init({});
  }, []);

  return (
    <div
      className="col-sm-5 col-lg-3 my-2 mx-2 cardContainer"
      style={styles.cardContainer}
      data-aos="fade"
      data-aos-delay="200"
    >
      <div>
        <img src={img} width="100%" alt="imagehere" />
      </div>
      <div className="mt-4 mb-3" style={styles.cardTitle}>
        <h2 className="h1g">{title}</h2>
      </div>
      <div className="px-4 cardParagraph" style={styles.cardParagraph}>
        <span>{paragraph}</span>
      </div>
      <div className="mt-4" style={styles.cardButtonContainer}>
        <Link to="/contact">
          <Button>Lets start today!</Button>
        </Link>
      </div>
    </div>
  );
}

export default Cards;
