import home1 from "../assets/images/home/1.png";
import home2 from "../assets/images/home/2.png";
import home3 from "../assets/images/home/3.png";
import home4 from "../assets/images/home/4.png";
import heroVid from "../assets/images/home/hero_vid.webm";
import heroVid1 from "../assets/images/home/hero_vid.mp4";

import about1 from "../assets/images/about/1.png";
import about2 from "../assets/images/about/2.png";
import about3 from "../assets/images/about/3.png";

import services1 from "../assets/images/services/1.png";
import services2 from "../assets/images/services/2.png";
import services3 from "../assets/images/services/3.png";
import services4 from "../assets/images/services/4.png";
import services5 from "../assets/images/services/5.png";
import services6 from "../assets/images/services/6.png";
import services7 from "../assets/images/services/7.png";
import services8 from "../assets/images/services/8.png";
import services9 from "../assets/images/services/9.png";
import services10 from "../assets/images/services/10.png";

import clients1 from "../assets/images/clients/1.png";
import clients2 from "../assets/images/clients/2.png";
import clients3 from "../assets/images/clients/3.png";
import clients4 from "../assets/images/clients/4.png";

import logo1 from "../assets/images/logo/1.png";
import logo2 from "../assets/images/logo/2.png";
import logo3 from "../assets/images/logo/3.png";

const homeImages = {
  home1,
  home2,
  home3,
  home4,
  heroVid,
  heroVid1,
};

const aboutImages = {
  about1,
  about2,
  about3,
};

const servicesImages = {
  services1,
  services2,
  services3,
  services4,
  services5,
  services6,
  services7,
  services8,
  services9,
  services10,
};
const clientsImages = {
  clients1,
  clients2,
  clients3,
  clients4,
};
const logoImages = {
  logo1,
  logo2,
  logo3,
};

export { homeImages, aboutImages, servicesImages, clientsImages, logoImages };
