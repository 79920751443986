import { Link } from "react-router-dom";
import { servicesImages } from "../../constants/images";
import { variables } from "../../constants/variables";
import "./ServicesBanner.css";
import "aos/dist/aos.css";
import AOS from "aos";
import { useEffect } from "react";

const styles = {
  button: {
    border: "3px solid",
    borderColor: variables.colors.border,
    borderRadius: "0px",
    color: variables.colors.text.primary,
    // fontFamily: "Helvetica Neue",
    fontSize: "20px",
    fontWeight: "500",
    padding: "5px 20px",
  },
};

function ServicesBanner({ children }) {
  useEffect(() => {
    AOS.init({});
  }, []);

  return (
    <div
      className="row mainBannerContainer d-flex align-items-end"
      style={{ padding: "0px", margin: "0px" }}
      data-aos="fade"
      data-aos-delay="200"
    >
      <img
        src={servicesImages.services10}
        style={{ padding: "0px", margin: "0px" }}
      />
    </div>
  );
}

export default ServicesBanner;
