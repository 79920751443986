import { useEffect } from "react";
import { Cards, Header, ServicesBanner } from "../../components";
import Footer from "../../components/Footer/Footer";
import { servicesImages } from "../../constants/images";
import { CardsList } from "../../containers";
import "./Services.css";

const data = [
  {
    img: servicesImages.services1,
    title: "Roofing",
    paragraph:
      "A building's roof is its first barrier against adverse weather.",
  },
  {
    img: servicesImages.services2,
    title: "Repair",
    paragraph:
      "We are expert in repaiers in Shungle roofs, flat roofs, metal, woodshakes, and more.",
  },
  {
    img: servicesImages.services3,
    title: "Solar",
    paragraph: "We install and remove Solar panels.",
  },
  {
    img: servicesImages.services4,
    title: "Gutters",
    paragraph:
      "Keeping your roof in excellent shape protects your building from water intrusion and the damaging effects of the sun.",
  },
  {
    img: servicesImages.services5,
    title: "Maintenance",
    paragraph:
      "Keep your roof maintained and replaced as needed according to building codes as well as keeping your building free of foundation cracks.",
  },
  {
    img: servicesImages.services6,
    title: "Emergencies",
    paragraph: "We are available 24 hours a day. 7 days a week.",
  },
  {
    img: servicesImages.services7,
    title: "Waterproofing",
    paragraph:
      "A building's roof is its first barrier against adverse weather.",
  },
  {
    img: servicesImages.services8,
    title: "Green",
    paragraph:
      "Keeping your roof in excellent shape protects your building from water intrusion and the damaging effects of the sun.",
  },
  {
    img: servicesImages.services9,
    title: "Skylights",
    paragraph: "Professional Skylight installation.",
  },
];

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-container">
      <Header></Header>
      <ServicesBanner></ServicesBanner>
      <CardsList data={data}></CardsList>
      <div className="spacing"></div>
      <Footer></Footer>
    </div>
  );
}

export default Services;
