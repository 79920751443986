import { Header } from "../../components";
import Footer from "../../components/Footer/Footer";
import { variables } from "../../constants/variables";
import "./Contact.css";
import { FiPhoneCall } from "react-icons/fi";
import { GoMail } from "react-icons/go";
import { IoMdPaperPlane } from "react-icons/io";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
// import { Mailer } from "nodemailer-react";
import { useLocation, useParams } from "react-router";

const styles = {
  title: {
    paddingBottom: "10px",
    color: variables.colors.text.primary,
  },
  helpHeading: {
    color: variables.colors.text.tertiary,
    letterSpacing: "2.2px",
  },
};

function Contact() {
  const redirectPath = window.location.origin;
  const [thankYouMsg, setThankYouMsg] = useState(false);

  useEffect(() => {
    AOS.init({});
    if (window.location.search === "?thank-you") {
      setThankYouMsg(true);
      setTimeout(() => {
        setThankYouMsg(false);
      }, 2800);
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main-container">
      {thankYouMsg ? (
        <div
          className="thankYou"
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            backgroundColor: "green",
            zIndex: 101,
            textAlign: "center",
            color: "white",
            padding: "10px 0px",
            fontWeight: "bold",
          }}
        >
          Thanks for contacting us. We will get back to you shortly.
        </div>
      ) : null}
      <Header></Header>
      <div
        className="row contactContainer"
        style={{
          padding: "80px 0px 40px 0px",
          margin: "0px",
        }}
        data-aos="fade"
        data-aos-delay="200"
      >
        <div className="col-lg-6 px-3 d-flex justify-content-end text-start">
          <div className="col-11 col-lg-8 d-flex flex-column justify-content-between pb-5">
            <div className="col-10 col-lg-8 d-flex flex-column justify-content-around py-2">
              <div className="h1g" style={styles.helpHeading}>
                NEED HELP?
              </div>
              <div className="" style={styles.title}>
                <h1 className="h1g">Contact us</h1>
              </div>
              <div
                style={{
                  fontSize: "medium",
                  color: variables.colors.text.secondary,
                }}
              >
                Thanks for your interest. For any inquiries, questions or
                commands, please fill out the form and I'll get back to you as
                soon as possible.
              </div>
            </div>
            <div className="row text-start my-2">
              <div className="col-1 py-3 mx-1 me-3">
                <FiPhoneCall size="32" />
              </div>
              <div className="col-10 col-md-9">
                <h4 className="py-0 my-0 h1g">Call</h4>
                <div>
                  <a href="tel:9547783444" className="tel">
                    954.778.3444
                  </a>
                </div>
                <div>
                  <a href="tel:5613981360" className="tel">
                    561.398.1360
                  </a>
                </div>
              </div>
            </div>
            <div className="row text-start my-2">
              <div className="col-1 mx-1 me-3">
                <GoMail size={40} />
              </div>
              <div className="col-10 col-md-9">
                <h4 className="py-0 my-0 h1g">Email</h4>
                <div>
                  <a href="mailto:info@roofologist101.com" className="tel">
                    info@roofologist101.com
                  </a>
                </div>
              </div>
            </div>
            <div className="row text-start my-2">
              <div className="col-3"></div>
              <div className="col-10">
                <h4 className="py-0 px-2 my-0 h1g">Lic.#CCC1326947</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-4">
          <div className="col-lg-9 d-flex flex-column justify-content-between text-start py-4">
            <form action="https://api.staticforms.xyz/submit" method="POST">
              <input
                type="hidden"
                name="accessKey"
                value="33b9065c-0468-49ac-91ce-a2ae20d69eb5"
              />
              <input
                className="form-inputs col-12"
                placeholder="Your Name"
                name="name"
                required
              />
              <input
                className="form-inputs col-12"
                placeholder="Your Email"
                name="email"
                required
                type="email"
              />
              <input
                className="form-inputs col-12"
                placeholder="Your Phone"
                name="phone"
                required
                // type="tel"
              />
              <textarea
                className="form-inputs col-12"
                rows={6}
                placeholder="Your Message Here"
                required
              ></textarea>
              <input
                type="hidden"
                name="redirectTo"
                value={`${redirectPath}/contact?thank-you`}
              />
              <button className="submitButton" type="submit">
                Submit Message
                <IoMdPaperPlane size={34} style={{ margin: "0px 10px" }} />
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Contact;
