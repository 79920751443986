import { Link } from "react-router-dom";
import { logoImages } from "../../constants/images";
import { variables } from "../../constants/variables";
import Button from "../Button/Button";
import Sidebar from "../Sidebar/Sidebar";
import "./Header.css";

const styles = {
  buttonStyle: {
    borderRadius: "15px",
    color: variables.colors.text.primary,
    fontSize: "20px",
    fontWeight: "500",
    padding: "10px 30px",
  },

  buttonStyle2: {
    border: "3px solid",
    borderColor: variables.colors.border,
    borderRadius: "50%",
    color: variables.colors.text.primary,
    fontSize: "20px",
    fontWeight: "500",
    height: "55px",
    width: "55px",
    textAlign: "center",
    justifyContent: "center",
  },
};

function Header({ children }) {
  return (
    <div
      className="row mainHeaderContainer"
      style={{ padding: "0px", margin: "0px" }}
    >
      <div className="col-4 col-md-6 mainLogoContainer">
        <Link to="/home">
          <img src={logoImages.logo3} className="headerLogo" />
        </Link>
      </div>
      <Sidebar></Sidebar>
    </div>
  );
}

export default Header;
