import { Cards } from "../../components";
import { variables } from "../../constants/variables";
import { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

const styles = {
  cardContainer: {
    backgroundColor: variables.shades.white,
    padding: "0px 0px",
    margin: "0px 0px",
  },
};

function CardsList({ data }) {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <div
      className="row d-flex justify-content-center py-3"
      data-aos="fade"
      data-aos-delay="200"
      style={styles.cardContainer}
    >
      {data.map((cd) => {
        return (
          <Cards img={cd.img} title={cd.title} paragraph={cd.paragraph}></Cards>
        );
      })}
    </div>
  );
}

export default CardsList;
