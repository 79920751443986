import { homeImages, logoImages } from "../../constants/images";
import { variables } from "../../constants/variables";
import Button from "../Button/Button";
import Sidebar from "../Sidebar/Sidebar";
import "./Hero.css";
import { Link } from "react-router-dom";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";

const styles = {
  buttonText: {
    padding: "10px 35px",
    borderRadius: "30px",
    color: variables.colors.text.white,
  },
  hamButtonStyle: {
    color: variables.colors.text.white,
  },
  fqButtonStyle: {
    color: variables.colors.text.white,
  },
};

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

function Hero() {
  const [autoP, setAutoP] = useState(true);
  const videoParentRef = useRef();
  const [safariBrowser, setSafariBrowser] = useState(false);

  useEffect(() => {
    if (isSafari()) {
      setSafariBrowser(true);
      //   const player = document.getElementById("vid");
      //   //   alert(videoParentRef.current);
      //   player.muted = true;
      //   player.setAttribute("muted", "");
      //   player.autoplay = true;
      //   player.autoPlay = true;
    } else {
    }
    setTimeout(function () {
      //   document.getElementById("vid").play();
    }, 1000);
  }, []);

  useLayoutEffect(() => {
    if (isSafari()) {
      //   const player = document.getElementById("vid");
      //   //   alert(videoParentRef.current);
      //   player.muted = true;
      //   player.setAttribute("muted", "");
      //   player.autoplay = true;
      //   player.autoPlay = true;
    } else {
    }
    setTimeout(function () {
      //   document.getElementById("vid").play();
    }, 1000);
  }, []);

  return (
    <div>
      <Sidebar
        hamButtonStyle={styles.hamButtonStyle}
        fqButtonStyle={styles.fqButtonStyle}
      ></Sidebar>
      <div className="heroContainer d-flex flex-column">
        {/* <ReactPlayer
          className="heroVideo"
          url="/hero_vid.webm"
          //   controls={true}
          //   url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
          loop={true}
          muted={true}
          playing={true}
          playsinline
          autoplay
          data-wf-ignore="true"
          data-object-fit="cover"
        /> */}
        {!safariBrowser ? (
          <video
            className="heroVideo"
            video
            loop
            muted
            autoPlay
            controls=""
            preload="none"
            id="vid"
            playsinline
            src={homeImages.heroVid}
            type="video/webm"
          ></video>
        ) : (
          <img className="heroVideo" id="vid" src={homeImages.heroVid1} />
        )}
        <div
          className="heroContainer2 d-flex flex-column"
          style={{ zIndex: 2 }}
        >
          <div className="heroImage">
            <Link to="/home">
              <img src={logoImages.logo2} width="90%" />
            </Link>
          </div>
          <div></div>
          <div></div>
          <div>
            <h1 className="heroHeading h1g">
              Top Roofologists
              <br />
              in Florida.
            </h1>
          </div>
          <div>
            <Link to="/contact">
              <Button buttonStyle={styles.buttonText}>
                Get started today!
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
