import { aboutImages } from "../../constants/images";
import { variables } from "../../constants/variables";
import CommonPoster from "../CommonPoster/CommonPoster";
import "./AboutPoster.css";
import { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

const styles = {
  topLeftContainer: {
    backgroundColor: variables.shades.light,
    padding: "25px 0px",
    minHeight: "450px",
    textAlign: "left",
  },
  topRightContainer: {
    padding: "0px",
  },
  midLeftContainer: {
    backgroundColor: variables.colors.primary,
    padding: "0px",
    minHeight: "400px",
  },
  midRightContainer: {
    padding: "40px 0px",
    minHeight: "400px",
    textAlign: "left",
    backgroundColor: variables.shades.white,
  },
  bullet: {
    color: variables.colors.text.tertiary,
    fontSize: "18px",
    paddingRight: "8px",
  },
  aboutContainer: {
    padding: "0px",
    margin: "0px 0px",
    borderTop: "8px solid",
    borderTopColor: variables.colors.primary,
  },
};

function AboutPoster() {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <div className="row" style={styles.aboutContainer}>
      <div
        className="col-lg-6 d-flex justify-content-center"
        style={styles.topLeftContainer}
        data-aos="fade"
        data-aos-delay="200"
      >
        <div className="col-1 col-lg-3"></div>
        <div className="col-10 col-lg-8 d-flex flex-column justify-content-around py-3">
          <div
            className=""
            style={{ paddingBottom: "10px", paddingTop: "50px" }}
          >
            <h5
              className="h1g"
              style={{
                color: variables.colors.text.tertiary,
                letterSpacing: "2.5px",
              }}
            >
              ABOUT
            </h5>
            <h1
              className="h1g"
              style={{
                color: variables.colors.text.primary,
                paddingBottom: "0px",
              }}
            >
              A heritage of roofing excellence
            </h1>
          </div>
          <div className="para">
            K Construction & Roofing LLC is a family owned roofing business that
            started in North Carolina in 1976, under Thomas Knight and family.
            The company then moved to South Florida and has held a triple A
            rating in Dade, Broward and Palm Beach County since his arrival in
            2003.
          </div>
          <div className="para">
            K Construction & Roofing LLC provides all types of services and
            repairs for roofing, from new roofs to flat roofs, commercial flate
            rookf, shingles, metal, and TPO. Our team of experts are all second
            generation roofers, please call us for a free consultation and
            quote.
          </div>
        </div>
        <div className="col-1 col-lg-1"></div>
      </div>
      <div
        className="col-lg-6 d-flex justify-content-center"
        style={styles.topRightContainer}
        data-aos="fade"
        data-aos-delay="200"
      >
        <img src={aboutImages.about3} width="100%" />
      </div>
      <div
        className="col-lg-6 d-flex justify-content-center"
        style={styles.midLeftContainer}
        data-aos="fade"
        data-aos-delay="200"
      >
        <img src={aboutImages.about2} width="100%" height="100%" />
      </div>
      <div
        className="col-lg-6 d-flex"
        style={styles.midRightContainer}
        data-aos="fade"
        data-aos-delay="200"
      >
        <div className="col-10 col-lg-9 d-flex flex-column px-5">
          <div
            className=""
            style={{
              paddingBottom: "10px",
              color: variables.colors.text.primary,
            }}
          >
            <h1 className="h1g">
              Trust the experts at Roofologist101 for all your roofing needs
            </h1>
          </div>
          <div>
            Our team of experts are highly qualified to install all types of
            roofing products and repairs, including.
          </div>
          <div>
            <ul
              style={{
                padding: "35px 0px 0px 0px",
                listStyle: "none",
                color: variables.colors.text.secondary,
              }}
            >
              <li>
                <span style={styles.bullet}>•</span> Shingles
              </li>
              <li>
                <span style={styles.bullet}>•</span> Tiles
              </li>
              <li>
                <span style={styles.bullet}>•</span> Flatroof
              </li>
              <li>
                <span style={styles.bullet}>•</span> TPO
              </li>
              <li>
                <span style={styles.bullet}>•</span> Metal
              </li>
              <li>
                <span style={styles.bullet}>•</span> Wood Shakes
              </li>
              <li>
                <span style={styles.bullet}>•</span> Rotted Wood
              </li>
              <li>
                <span style={styles.bullet}>•</span> All Repairs
              </li>
            </ul>
          </div>
        </div>
      </div>
      <CommonPoster></CommonPoster>
    </div>
  );
}

export default AboutPoster;
