import { Link } from "react-router-dom";
import { logoImages } from "../../constants/images";
import { variables } from "../../constants/variables";
import Button from "../Button/Button";
import "./Sidebar.css";
import { FaUserClock, FaBars } from "react-icons/fa";
import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import cx from "classnames";

const styles = {
  buttonStyle: {
    borderRadius: "15px",
    color: variables.colors.text.primary,
    fontSize: "20px",
    fontWeight: "500",
    padding: "10px 30px",
  },
  buttonStyleOpen: {
    borderRadius: "15px",
    color: variables.colors.text.white,
    fontSize: "20px",
    fontWeight: "500",
    padding: "10px 30px",
  },

  buttonStyle2: {
    border: "2.5px solid",
    borderColor: variables.colors.border,
    borderRadius: "50%",
    color: variables.colors.text.primary,
    fontSize: "20px",
    fontWeight: "500",
    height: "55px",
    width: "55px",
    textAlign: "center",
    justifyContent: "center",
  },
};

function Sidebar({ children, fqButtonStyle, hamButtonStyle }) {
  useEffect(() => {
    AOS.init({});
  }, []);

  const [sidebarModal, setSidebarModal] = useState(false);
  return (
    <div
      className="mainSidebarContainer d-flex justify-content-end align-items-end px-3"
      // data-aos="fade"
      // data-aos-delay="200"
    >
      {sidebarModal ? (
        <div className="d-sm-flex justify-content-end fqButtonOpen ffq">
          <Link to="/contact">
            <Button
              buttonStyle={{ ...styles.buttonStyleOpen, ...fqButtonStyle }}
            >
              <FaUserClock
                style={{ margin: "0px 20px 3px 0px" }}
                className="fqButtonOpen"
              />
              FREE QUOTE
            </Button>
          </Link>
        </div>
      ) : (
        <div className="d-sm-flex justify-content-end d-none fqButton">
          <Link to="/contact">
            <Button
              buttonStyle={{ ...styles.buttonStyle, ...fqButtonStyle }}
              className="fqButton"
            >
              <FaUserClock style={{ margin: "0px 20px 3px 0px" }} />
              FREE QUOTE
            </Button>
          </Link>
        </div>
      )}
      {sidebarModal ? (
        <div className="px-3 hamButton" onClick={() => setSidebarModal(false)}>
          <button
            className="btn"
            style={{ ...styles.buttonStyle2, ...hamButtonStyle }}
          >
            <RxCross2 color={variables.colors.text.tertiary} />
          </button>
        </div>
      ) : (
        <div className="px-3 hamButton" onClick={() => setSidebarModal(true)}>
          <button
            className="btn"
            style={{ ...styles.buttonStyle2, ...hamButtonStyle }}
          >
            <FaBars />
          </button>
        </div>
      )}
      {/* {sidebarModal ? ( */}
      <div
        className={cx("sidebarOpen d-flex align-items-center", {
          "sidebar-closed": !sidebarModal,
        })}
      >
        <div className="col-md-8 d-flex flex-column px-5">
          <Link to="/home" className="sidebarLinks">
            Home
          </Link>
          <Link to="/about" className="sidebarLinks">
            About
          </Link>
          <Link to="/services" className="sidebarLinks">
            Services
          </Link>
          <Link to="/contact" className="sidebarLinks">
            Contact
          </Link>
        </div>
      </div>
      {/* ) : null} */}
    </div>
  );
}

export default Sidebar;
