import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import { About, Contact, Home, Services } from "./pages";

export const router = createBrowserRouter([
  { path: "/about", element: <About /> },
  { path: "/contact", element: <Contact /> },
  { path: "/home", element: <Home /> },
  { path: "/services", element: <Services /> },
  { path: "*", element: <Home /> },
]);
