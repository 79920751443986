import { clientsImages } from "../../constants/images";
import { variables } from "../../constants/variables";
import "./Clients.css";
import { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

const styles = {
  button: {
    border: "1px solid",
    borderColor: variables.colors.border,
    borderRadius: "0px",
    color: variables.colors.text.primary,
    fontSize: "22px",
    fontWeight: "500",
    padding: "10px 40px",
  },
};

const data = [
  {
    img: clientsImages.clients1,
    width: "95%",
  },
  {
    img: clientsImages.clients2,
    width: "103%",
  },
  {
    img: clientsImages.clients3,
    width: "95%",
  },
  {
    img: clientsImages.clients4,
    width: "90%",
  },
];

function Clients({ children, buttonStyle }) {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <div
      className="row d-flex justify-content-center"
      style={{ backgroundColor: "white", padding: "50px 0px", margin: "0px" }}
    >
      <div
        className="col-md-10 d-flex justify-content-center"
        data-aos="fade"
        data-aos-delay="200"
      >
        <div
          className="row d-flex justify-content-center"
          style={{ margin: "0px", padding: "0px" }}
        >
          {data.map((ci) => {
            return (
              <div className="col-4 col-md-2 mx-4 my-3">
                <img src={ci.img} width={ci.width} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Clients;
