import { variables } from "../../constants/variables";
import "./Button.css";

const styles = {
  button: {
    border: "3px solid",
    borderColor: variables.colors.border,
    borderRadius: "0px",
    color: variables.colors.text.primary,
    // fontFamily: "Helvetica Neue",
    fontSize: "20px",
    // fontWeight: "500",
    padding: "5px 20px",
    background: "transparent",
  },
};

function Button({ children, buttonStyle }) {
  return (
    <button className="h1g" style={{ ...styles.button, ...buttonStyle }}>
      {children}
    </button>
  );
}

export default Button;
