import { variables } from "../../constants/variables";
import Button from "../Button/Button";
import { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import { Link } from "react-router-dom";

const styles = {
  containerLayout: {
    minHeight: "450px",
    textAlign: "left",
  },
  bottomLeftContainer: {
    backgroundColor: variables.colors.primary,
    padding: "50px 0px",
  },
  bottomRightContainer: {
    backgroundColor: variables.shades.light,
    padding: "100px 0px",
  },
  buttonStyle: {
    borderRadius: "50px",
    padding: "8px 30px",
    backgroundColor: variables.colors.border,
  },
  topParagraph: {
    color: variables.colors.text.secondary,
  },
  bottomLeftTitle: {
    paddingHorizontal: "10px",
    color: variables.colors.text.white,
  },
  title: {
    paddingHorizontal: "10px",
    color: variables.colors.text.primary,
    fontWeight: "bolder",
  },
  testimonialHeading: {
    color: variables.colors.text.tertiary,
  },
};

function CommonPoster() {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <div
      className="row"
      style={{ padding: 0, margin: 0 }}
      data-aos="fade"
      data-aos-delay="200"
    >
      <div
        className="col-lg-6 d-flex justify-content-center"
        style={{ ...styles.containerLayout, ...styles.bottomLeftContainer }}
      >
        <div className="col-10 col-lg-6 d-flex flex-column justify-content-around">
          <div style={styles.testimonialHeading}>CLIENT TESTIMONIALS</div>
          <div className="" style={styles.bottomLeftTitle}>
            <h1 className="h1g">What our customers say about us</h1>
          </div>
          <div>
            <a href="https://g.co/kgs/ob8DRc" target="_blank">
              <Button buttonStyle={styles.buttonStyle}>
                Read more reviews
              </Button>
            </a>
          </div>
        </div>
      </div>
      <div
        className="col-lg-6 d-flex justify-content-center"
        style={{ ...styles.containerLayout, ...styles.bottomRightContainer }}
      >
        <div className="col-10 col-lg-9 d-flex flex-column justify-content-around">
          <div style={styles.title}>
            <h2 className="h1g">It Was a Great Experience!</h2>
          </div>
          <div>
            "This company installed my roofing very fast.
            <br />
            Thank you Charlie"
          </div>
          <div>
            <i>~Sammy C</i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommonPoster;
