import { useEffect } from "react";
import { AboutPoster, Header } from "../../components";
import Clients from "../../components/Clients/Clients";
import Footer from "../../components/Footer/Footer";
import "./About.css";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-container">
      <Header></Header>
      <AboutPoster></AboutPoster>
      <Clients></Clients>
      <Footer></Footer>
    </div>
  );
}

export default About;
