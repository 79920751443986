import { Link } from "react-router-dom";
import { logoImages } from "../../constants/images";
import { variables } from "../../constants/variables";
import "./Footer.css";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { TiWorld } from "react-icons/ti";
import { FaFacebookF } from "react-icons/fa";
import { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

const styles = {
  button: {
    border: "3px solid",
    borderColor: variables.colors.border,
    borderRadius: "0px",
    color: variables.colors.text.primary,
    // fontFamily: "Helvetica Neue",
    fontSize: "20px",
    fontWeight: "500",
    padding: "5px 20px",
  },
};

function Footer({ children }) {
  useEffect(() => {
    AOS.init({});
  }, []);

  return (
    <div
      className="row py-5 mainContainer d-flex align-items-end"
      style={{ padding: "0px", margin: "0px" }}
      data-aos="fade"
      data-aos-delay="200"
    >
      <div className="col-md-4 px-5 my-2">
        <Link to="/home">
          <img src={logoImages.logo2} width="90%" />
        </Link>
      </div>
      <div className="col-md-3 footerLinks3 infoContainer my-2 px-4">
        K Construction Roofing
        <br />
        2000 PGA Blvd Ste 4440 #179
        <br />
        Palm Beach Gardens, FL 33408 <br />
        <a href="mailto:info@roofologist101.com" className="tel2">
          info@roofologist101.com
        </a>
        <br />
        <a href="tel:9547783444" className="tel2">
          954.778.3444
        </a>
      </div>
      <div className="col-md-1 pb-4">
        <div className="row" style={{ padding: "0px", margin: "0px" }}>
          <Link to="/home" className="footerLinks">
            Home
          </Link>
          <Link to="/about" className="footerLinks">
            About
          </Link>
          <Link to="/services" className="footerLinks">
            Services
          </Link>
          <Link to="/contact" className="footerLinks">
            Contact
          </Link>
        </div>
      </div>
      <div className="col-md-4 pb-4 px-4">
        <div
          className="row footerSocials text-start d-flex justify-content-start"
          style={{ padding: "10px 0px", margin: "0px" }}
        >
          <div className="col-1 col-md-1 footerSocialIcons p-0">
            <BsInstagram />
          </div>
          <div className="col-1 col-md-1 footerSocialIcons p-0">
            <BsTwitter />
          </div>
          <div className="col-1 col-md-1 footerSocialIcons p-0">
            <FaFacebookF />
          </div>
          <div className="col-1 col-md-1 footerSocialIcons p-0">
            <TiWorld size={22} />
          </div>
        </div>
        <div
          className="row footerLinks2"
          style={{ padding: "2px 0px", margin: "0px", float: "left" }}
        >
          © K CONSTRUCTION & ROOFING LLC. ALL RIGHTS RESERVED |
          <a
            className="footerLinks2"
            href="https://www.designbysunman.com"
            target="_blank"
            style={{
              display: "inline",
              float: "left",
              padding: "0px 0px 0px 4px",
              margin: "0px",
              width: "auto",
            }}
          >
            WEBSITE DESIGN BY SUNMAN
          </a>
        </div>
        {/* <FA */}
      </div>
    </div>
  );
}

export default Footer;
